<template>
  <div v-if="options.master">
    <component :is="options.master" :master="true" :debug="false" @masterUpdated="onMasterUpdated" @parentHide="onCancel" />
  </div>
  <div v-else>
    <CCard>
      <CCardHeader>{{ form.id ? $t(`Edit ${title}`) + ` id: ${form.id}` : $t(`Create ${title}`) }}</CCardHeader>
      <CCardBody>
        <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')" @submit.prevent>
          <AInput label="Name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
        </CForm>
      </CCardBody>
      <CCardFooter>
        <CButton v-if="!options.only_form" type="button" class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="onSubmit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
        <CButton v-if="!options.only_form" type="button" color="secondary" @click="onResetForm">{{ $t('Reset') }}</CButton>

        <CButton type="button" class="float-right" color="secondary" @click="onCancel">{{ $t('Cancel') }}</CButton>
        <CButton type="button" class="float-right mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="onSubmit(true)">{{ $t((form.id ? 'Save' : 'Create') + ' & Close') }}</CButton>
      </CCardFooter>
    </CCard>

    <ACRUDTable v-if="!options.only_form" ref="crud_table" :fields="['id', 'name']" :config="options" @editItem="onEditItem" @dataChanged="onDataChanged" />
  </div>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

export default {
  name: 'ModalCRUD',
  inheritAttrs: true,
  mixins: [formMixin],
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      return this.options.title.toLowerCase()
    }
  },
  data() {
    return {
      form: { name: '' },
      items: [],
      close: false
    }
  },
  beforeDestroy() {
    console.log(this.items)
    //this.onDataChanged(this.items)
    this.$emit('modalUpdated', this.items)
  },
  methods: {
    onEditItem(item) {
      this.form = (({ id, name }) => ({ id, name }))(item)
    },
    onResetForm() {
      this.form = { name: '' }
    },
    onDataChanged(items) {
      if (this.options.master) return
      // TODO: replace with backend list
      this.items = (items || []).map(item => ({ id: item.id, name: item.name, value: item.id }))
      //this.$emit('modalUpdated', this.items)
      if (this.close === true) this.onCancel(true)
    },
    // TODO: move this when closed ?
    onMasterUpdated(config) {
      const self = this
      if (config.entity) {
        this.$emit('modalUpdated', ['_single', config.entity]) // TODO: Improve this
      } else {
        self.$http
          .get(config.url)
          .then(response => {
            this.$emit('modalUpdated', response.data[config.entity])
          })
          .catch(error => {
            console.error(error)
            self.showAlert(`There was an error retrieving master rows.`, 'danger')
          })
      }
    },
    onCancel(updated) {
      //console.log('onCancel', updated)
      this.onResetForm()
      this.$emit('parentHide', updated)
    },
    /*async getCrudData() {
      this.$refs.crud_table.getData()
    },*/
    onSubmit(close) {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      for (const item of this.items) {
        if (this.form.id !== item.id && this.form.name.toLowerCase() === item.name.toLowerCase()) {
          //console.log({ name: ['The :attribute has already been taken.'] })
          this.setErrors({ name: ['El nombre ya existe.'] })
          return
        }
      }

      const self = this
      self.$http[self.form.id ? 'put' : 'post'](self.options.url, { ...self.form, ...(self.options.payload || {}) }) // TODO: on service ?
        .then(response => {
          self.showAlert(`${self.title} ${response.data[self.options.name].id ? 'saved' : 'created'}.`)
          if (self.$refs.crud_table) self.$refs.crud_table.getData(false, false, false, close === true ? () => self.onCancel(true) : false)
          self.onResetForm()
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log(error.response.data.errors)
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the ${self.options.title}.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
